@.generalSiteHandler = ->
    $(document).ready( ->
        bindVideoPlayertHandler()
        bindHeaderMenuHandler()
        bindSmoothScroolHandler()
    )

    bindVideoPlayertHandler = ->
        videoContainer = $('#video-container')
        videoPlayer = $('#videoPlayer')
        videoContainer.hide()

        $('#showButton').on('click', ->
            videoContainer.fadeIn()
            videoPlayer.get(0).play()
        )

        videoContainer.on('click', (event) ->
            return if event.target != this
            $(this).fadeOut()
            videoPlayer.get(0).pause()
        )

    bindHeaderMenuHandler = ->
        $('.header__right__menu__container').on('click', ->
             $(this).toggleClass('responsive')
        )

    bindSmoothScroolHandler = ->
          $('a[href*="#"]:not([href="#"])').click ->
            if location.pathname.replace(/^\//, '') == @pathname.replace(/^\//, '') and location.hostname == @hostname
              target = $(@hash)
              target = if target.length then target else $('[name=' + @hash.slice(1) + ']')
              if target.length
                $('html, body').animate { scrollTop: target.offset().top }, 300
                target.focus()
                if target.is(':focus')
                  return false
                else
                  target.attr 'tabindex', '-1'
                  target.focus()
                return false
            return
          return
